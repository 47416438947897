import travel from '../img/travel.jpeg';
import map from '../img/30min.jpeg';

const Travel = () => {
    return (
        <>
            <div class="!w-full">
                <img class="!object-cover !w-full !h-[25vh] md:!h-[50vh]" src={travel} alt="Modern building architecture" />
            </div>
            <div class="bg-white h-full">
                <div class="flex p-6 justify-center">
                    <article class="prose lg:prose-xl w-full">
                        <h1>Travel:</h1>
                        <p>If you're coming from out of state, we recommend staying at one of the hotels listed below. Each one has a guest rating of 4.5 stars or higher and is less than 30 minutes from the venue.</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Hotel</th>
                                    <th>Nightly Rate</th>
                                    <th>Distance from Venue</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a href="https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=HFDSTHW&arrivalDate=2024-09-19&departureDate=2024-09-20&room1NumAdults=2&inputModule=HOTEL_SEARCH&viewPackagesAndPromotionsRate=true&WT.mc_id=zLADA0WW1XX2OLX3DA4MS5MS6_HFDSTHW7_298227037_&adType=FL&dsclid=61389968948862976&dclid=COva-NqV5oUDFSyhgwgdR7UIAw&hmGUID=285da6ae-93f9-48f8-9921-6c101051f0fd">Homewood Suites by Hilton Southington, CT</a></td>
                                    <td>$194</td>
                                    <td>24 minutes</td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.choicehotels.com/connecticut/south-windsor/cambria-hotels/ct136?gal=undefined&gclid=CjwKCAjw57exBhAsEiwAaIxaZvjE04PlzWGtYHi6uBlodk1t_mKH4P2IzZD7jtsaXo6zE_iml9NukhoCRsUQAvD_BwE&gmp=MetaAd&gpa=GPADSAPR2&hmGUID=285da6ae-93f9-48f8-9921-6c101051f0fd&mc=HAGOHPUS&meta=PMFGPADUSSAPR2_CT136_mapresults_US_1_desktop_2024-09-19_selected_20098951185__paid&pmf=hpagoogle&product=mapresults&adults=2&checkInDate=2024-09-19&checkOutDate=2024-09-20&ratePlanCode=SAPR2">Cambria Hotel Manchester South Windsor</a></td>
                                    <td>$148</td>
                                    <td>22 minutes</td>
                                </tr>
                                <tr>
                                    <td><a href="https://bookings.travelclick.com/5116?adults=2&children=0&currency=USD&datein=09/19/2024&domain=www.google.com&gdp=hotelfinder&hotelID=5116&languageid=1&nights=1&rateplanID=13102&roomtypeID=8462&subchan=GOOGLE_US_desktop&utm_campaign=ds_&utm_content=HPA_5116_mapresults_1_US_desktop_2024-09-19_selected___organic&utm_medium=meta&utm_source=googleha#/accommodation/room">Simsbury 1820 House</a></td>
                                    <td>$169</td>
                                    <td>20 minutes</td>
                                </tr>
                            </tbody>
                        </table>
                        <img src={map}/>
                    </article>
                </div>

            </div>

        </>

    )
}

export default Travel;