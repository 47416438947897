import { Divider } from '@nextui-org/react';
import schedule from '../img/schedule.jpeg';

const Schedule = () => {
    return (
        <>
            <div class="!w-full">
                <img class="!object-cover !w-full !h-[25vh] md:!h-[50vh]" src={schedule} alt="Modern building architecture" />
            </div>
            <div class="bg-white  h-screen">
                <div class="flex p-6 justify-center">
                    <article class="prose lg:prose-xl">
                    <h1>Schedule:</h1>
                        <div class="grid grid-cols-2 gap-y-6">
                            <div class="content-center" >
                                <h4 class="!m-0">4:30PM</h4>
                            </div>
                            <div>
                                <h3 class="!m-0">Venue Doors Open</h3>
                            </div>
                            <div class="content-center">
                                <h4 class="!m-0">5:00PM</h4>
                            </div>
                            <div>
                                <h3 class="!m-0">Ceremony</h3>
                            </div>
                            <div class="content-center">
                                <h4 class="!m-0">5:30PM</h4>
                            </div>
                            <div>
                                <h3 class="!m-0">Cocktail Hour</h3>
                            </div>
                            <div class="content-center">
                                <h4 class="!m-0">6:00PM</h4>
                            </div>
                            <div>
                                <h3 class="!m-0">Dinner & Cake</h3>
                            </div>
                            <div class="content-center">
                                <h4 class="!m-0">9:00PM</h4>
                            </div>
                            <div>
                                <h3 class="!m-0">Venue Closes</h3>
                            </div>
                        </div>
                        <Divider className="my-4" />
                    </article>
                </div>

            </div>

        </>

    )
}

export default Schedule;