import weddingparty from '../img/weddingparty.jpeg';

const WeddingParty = () => {
    return (
        <>
            <div class="!w-full">
                <img class="!object-cover !w-full !h-[25vh] md:!h-[50vh]" src={weddingparty} alt="Modern building architecture" />
            </div>
            <div class="bg-white  h-full">
                <div class="flex p-6 justify-center">
                    <article class="prose lg:prose-xl w-full">
                        <h1>Wedding Party:</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sarah Sullivan</td>
                                    <td>Officiant</td>
                                </tr>
                                <tr>
                                    <td>Carol & Steven Porsch</td>
                                    <td>Parents of the Bride</td>
                                </tr>
                                <tr>
                                    <td>Harvey & Tamara McLendon</td>
                                    <td>Parents of the Groom</td>
                                </tr>
                                <tr>
                                    <td>Suzanne & Greg Dorsett</td>
                                    <td>Parents of the Groom</td>
                                </tr>
                                <tr>
                                    <td>Juliana Servetnick</td>
                                    <td>Maid of Honor</td>
                                </tr>
                                <tr>
                                    <td>Christa Petro</td>
                                    <td>Bridesmaid</td>
                                </tr>
                                <tr>
                                    <td>Francesca Fontanez</td>
                                    <td>Bridesmaid</td>
                                </tr>
                                <tr>
                                    <td>Monica Mulligan</td>
                                    <td>Bridesmaid</td>
                                </tr>
                                <tr>
                                    <td>Ben McLendon</td>
                                    <td>Best Person</td>
                                </tr>
                                <tr>
                                    <td>Cole McLendon</td>
                                    <td>Groomsman</td>
                                </tr>
                                <tr>
                                    <td>Matthew Porsch</td>
                                    <td>Groomsman</td>
                                </tr>
                                <tr>
                                    <td>Josh Hinostroza</td>
                                    <td>Groomsman</td>
                                </tr>
                            </tbody>
                        </table>
                    </article>
                </div>

            </div>

        </>

    )
}

export default WeddingParty;