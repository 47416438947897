import { NextUIProvider } from "@nextui-org/react";
import { useNavigate, useLocation, Route, Routes } from 'react-router-dom';
import { Navbar, NavbarContent, NavbarItem, Link, NavbarMenuToggle, NavbarMenu, NavbarMenuItem } from "@nextui-org/react";
import { useState } from 'react';

import './App.css';
import FAQ from './pages/FAQ';
import Schedule from "./pages/Schedule";
import Travel from "./pages/Travel";
import WeddingParty from "./pages/WeddingParty";

function App() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    "FAQ",
    "Registry",
    "RSVP",
    "Schedule",
    "Travel",
    "Wedding Party"
  ];

  return (
    <NextUIProvider navigate={navigate}>
      <Navbar isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} >
        <NavbarContent className="sm:p-0 sm:pt-6 flex justify-start sm:!justify-center">
          <h1 className="josefin-slab-title !font-bold text-2xl sm:text-5xl sm:font-black">Lauren & Reid</h1>
        </NavbarContent>
        <NavbarContent className="sm:hidden max-w-fit" justify="end">
          <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
        </NavbarContent>
        <NavbarMenu>
          <NavbarMenuItem isActive={pathname === '/' ? true : false}>
            <Link
              className="w-full"
              href="/"
              size="lg"
            >
              FAQ
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link
              className="w-full"
              href="https://www.zola.com/registry/reidandlauren2024"
              size="lg"
            >
              Registry
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem >
            <Link
              className="w-full"
              href="https://docs.google.com/forms/d/e/1FAIpQLSePBhpvIVWR8H4oRubTCkyItjHyj64dQJh0oB7dx52OjhO6ug/viewform?pli=1"
              size="lg"
            >
              RSVP
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem isActive={pathname === '/schedule' ? true : false}>
            <Link
              className="w-full"
              href="/schedule"
              size="lg"
            >
              Schedule
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem isActive={pathname === '/travel' ? true : false}>
            <Link
              className="w-full"
              href="/travel"
              size="lg"
            >
              Travel
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem isActive={pathname === '/wedding-party' ? true : false}>
            <Link
              className="w-full"
              href="/wedding-party"
              size="lg"
            >
              Wedding Party
            </Link>
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>
      <Navbar className="hidden sm:flex justify-center" >
        <NavbarContent className="hidden sm:flex">
        </NavbarContent>
        <NavbarContent className="hidden sm:flex justify-center">
          <NavbarItem isActive={pathname === '/' ? true : false}>
            <Link className="text-lg" color={pathname === '/' ? 'primary' : 'secondary'} href="/">
              FAQ
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="text-lg" color="secondary"  href="https://www.zola.com/registry/reidandlauren2024">
              Registry
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="text-lg" color="secondary"  href="https://docs.google.com/forms/d/e/1FAIpQLSePBhpvIVWR8H4oRubTCkyItjHyj64dQJh0oB7dx52OjhO6ug/viewform?pli=1">
              RSVP
            </Link>
          </NavbarItem>
          <NavbarItem isActive={pathname === '/schedule' ? true : false}>
            <Link className="text-lg" color={pathname === '/schedule' ? 'primary' : 'secondary'}  href="/schedule">
              Schedule
            </Link>
          </NavbarItem>
          <NavbarItem isActive={pathname === '/travel' ? true : false}>
            <Link className="text-lg" color={pathname === '/travel' ? 'primary' : 'secondary'} href="/travel">
              Travel
            </Link>
          </NavbarItem>
          <NavbarItem isActive={pathname === '/wedding-party' ? true : false}>
            <Link className="text-lg" color={pathname === '/wedding-party' ? 'primary' : 'secondary'} href="/wedding-party">
              Wedding Party
            </Link>
          </NavbarItem>
        </NavbarContent>
        <NavbarContent className="hidden sm:flex">
        </NavbarContent>
      </Navbar>
      <Routes >
        <Route path="/" element={<FAQ />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/travel" element={<Travel />} />
        <Route path="/wedding-party" element={<WeddingParty />} />
      </Routes>
    </NextUIProvider>
  );
}

export default App;
