import faq from '../img/faq.jpeg';

const FAQ = () => {
    return (
        <>
            <div class="!w-full">
                <img class="!object-cover !w-full !h-[25vh] md:!h-[50vh]" src={faq} alt="Modern building architecture" />
            </div>
            <div class="bg-white  h-full">
                <div class="flex p-6 justify-center">
                    <article class="prose lg:prose-xl">
                        <h1>FAQ:</h1>
                        <blockquote>
                            <p>Uh, when / where is the wedding?</p>
                        </blockquote>
                        <p>
                            Hi, thanks for asking! We're getting married at the <b>Pond House Cafe</b> in West Hartford, CT on <b>Thursday, September 19th, 2024</b>. A rough itinerary can be found on the <a href="/schedule">Schedule</a> page
                        </p>
                        <blockquote>
                            <p>What should I wear? What is the dress code?</p>
                        </blockquote>
                        <p>The dress code is semi-formal. We know that sounds vague, so we'd like to share <a href="https://www.brides.com/semi-formal-wedding-attire-4800687">this article</a> that helps explain what we're going for.</p>
                        <blockquote>
                            <p>Whats the food and beverage situation?</p>
                        </blockquote>
                        <p>There will be an open bar accompanied by a buffet-style dinner, with a vegetarian option available. Please let us know if you have any dietary restrictions you'd like us to be aware of when you <a href="https://docs.google.com/forms/d/e/1FAIpQLSePBhpvIVWR8H4oRubTCkyItjHyj64dQJh0oB7dx52OjhO6ug/viewform?pli=1">RSVP</a></p>
                        <blockquote>
                            <p>And what of the entertainment??</p>
                        </blockquote>
                        <p>There will be a DJ and we will provide a bunch of fun board games and table games to play!</p>
                        <blockquote>
                            <p>When should I RSVP?</p>
                        </blockquote>
                        <p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSePBhpvIVWR8H4oRubTCkyItjHyj64dQJh0oB7dx52OjhO6ug/viewform?pli=1">RSVP</a> no later than August 19th, 2024.</p>
                    </article>
                </div>

            </div>

        </>

    )
}

export default FAQ;